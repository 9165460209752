import { Formik, getIn } from 'formik';
import { number, object, string } from 'yup';
import { Address } from '../../@types/address';
import { Textarea } from '../Textarea';
import { ActionButton } from '../action-button';
import { Input } from '../input';
import { ActionButtonsWrapper, RowContainer } from './style';

const validateString = string().typeError('הערך חייב להיות טקסט');
const validateRequiredString = validateString.required('שדה חובה');
const validateNumber = number().typeError('הערך חייב להיות מספר');
const validateRequiredNumber = validateNumber.required('חובה להזין ערך מספרי');
const validateNullableNumber = number().nullable().typeError('הערך חייב להיות מספר');

const addressSchema = object<Address>({
  city: validateRequiredString,
  street: validateRequiredString,
  buildingNumber: validateRequiredNumber,
  entrance: string().nullable(),
  addressInstructions: string().nullable(),
  code: string().nullable(),
  floor: validateNullableNumber,
  apartmentNumber: validateNullableNumber,
  apartmentNotes: string().nullable(),
  familyName: string().nullable(),
  familyPhoneNumber: string().nullable(),
  generalNotes: string().nullable(),
  internalNotes: string().nullable(),
  contactName: string().nullable(),
  contactPhoneNumber: string().nullable(),
  contactTitle: string().nullable(),
  location: object({
    latitude: validateNullableNumber,
    longitude: validateNullableNumber,
  }),
  totalPackages: validateRequiredNumber.positive('הערך חייב להיות גדול מ-0'),
});

type AddressFormProps = {
  address: Partial<Address>;
  onSaveClick: (address: Address) => Promise<void>;
  disableEditing?: boolean;
};

// todo: onbeforeunload

export const AddressForm = ({ address, onSaveClick, disableEditing = false }: AddressFormProps) => {
  return (
    <>
      <Formik
        initialValues={address}
        enableReinitialize
        validateOnChange
        onSubmit={(values, { setSubmitting }) => {
          onSaveClick(values as Address).then(() => {
            setSubmitting(false);
          });
        }}
        validationSchema={addressSchema}
      >
        {({ values, handleSubmit, handleBlur, handleChange, errors, touched, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <RowContainer>
              <Input
                name="city"
                type="text"
                value={values.city ?? ''}
                label="עיר"
                disabled
                required
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.city}
                hasError={!!errors.city && touched.city}
              />
            </RowContainer>

            <RowContainer>
              <Input
                name="street"
                type="text"
                value={values.street ?? ''}
                disabled={disableEditing}
                label="רחוב"
                required
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.street}
                hasError={!!errors.street && touched.street}
              />
            </RowContainer>

            <RowContainer>
              <Input
                name="buildingNumber"
                type="number"
                value={values.buildingNumber ?? ''}
                disabled={disableEditing}
                label="מספר בית"
                required
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.buildingNumber}
                hasError={!!errors.buildingNumber && touched.buildingNumber}
              />
              <Input
                name="entrance"
                type="text"
                value={values.entrance ?? ''}
                disabled={disableEditing}
                label="כניסה"
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.entrance}
                hasError={!!errors.entrance && touched.entrance}
              />
            </RowContainer>

            <RowContainer>
              <Input
                name="floor"
                type="number"
                value={values.floor ?? ''}
                disabled={disableEditing}
                label="קומה"
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.floor}
                hasError={!!errors.floor && touched.floor}
              />
              <Input
                name="apartmentNumber"
                type="number"
                value={values.apartmentNumber ?? ''}
                disabled={disableEditing}
                label="דירה"
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.apartmentNumber}
                hasError={!!errors.apartmentNumber && touched.apartmentNumber}
              />
            </RowContainer>

            <RowContainer>
              <Input
                name="familyName"
                type="text"
                value={values.familyName ?? ''}
                disabled={disableEditing}
                label="שם משפחה"
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.familyName}
                hasError={!!errors.familyName && touched.familyName}
              />
              <Input
                name="familyPhoneNumber"
                type="tel"
                style={{ direction: 'ltr', textAlign: 'right' }}
                value={values.familyPhoneNumber ?? ''}
                disabled={disableEditing}
                label="טלפון"
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.familyPhoneNumber}
                hasError={!!errors.familyPhoneNumber && touched.familyPhoneNumber}
              />
            </RowContainer>

            <RowContainer>
              <Input
                name="code"
                type="text"
                style={{ direction: 'ltr', textAlign: 'right' }}
                value={values.code ?? ''}
                disabled={disableEditing}
                label="קוד"
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.code}
                hasError={!!errors.code && touched.code}
              />
            </RowContainer>

            <RowContainer>
              <Input
                name="location.longitude"
                type="number"
                value={values.location?.longitude ?? ''}
                label="קו אורך"
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={getIn(errors, 'location.longitude')}
                hasError={
                  !!getIn(errors, 'location.longitude') && getIn(touched, 'location.longitude')
                }
                disabled
              />
              <Input
                name="location.latitude"
                type="number"
                value={values.location?.latitude ?? ''}
                label="קו רוחב"
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={getIn(errors, 'location.latitude')}
                hasError={
                  !!getIn(errors, 'location.latitude') && getIn(touched, 'location.latitude')
                }
                disabled
              />
            </RowContainer>

            <RowContainer>
              <Textarea
                name="addressInstructions"
                rows={3}
                label="הוראות הגעה לכתובת"
                disabled={disableEditing}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.addressInstructions ?? ''}
                errorMessage={errors.addressInstructions}
                hasError={!!errors.addressInstructions && touched.addressInstructions}
              />
            </RowContainer>

            <RowContainer>
              <Textarea
                name="apartmentNotes"
                rows={3}
                label="פרטים לזיהוי הדירה"
                disabled={disableEditing}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.apartmentNotes ?? ''}
                errorMessage={errors.apartmentNotes}
                hasError={!!errors.apartmentNotes && touched.apartmentNotes}
              />
            </RowContainer>

            <RowContainer>
              <Textarea
                name="generalNotes"
                rows={3}
                label="הערות נוספות"
                disabled={disableEditing}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.generalNotes ?? ''}
                errorMessage={errors.generalNotes}
                hasError={!!errors.generalNotes && touched.generalNotes}
              />
            </RowContainer>

            <RowContainer>
              <Input
                name="contactName"
                type="text"
                value={values.contactName ?? ''}
                disabled={disableEditing}
                label="שם איש קשר"
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.contactName}
                hasError={!!errors.contactName && touched.contactName}
              />
              <Input
                name="contactPhoneNumber"
                type="tel"
                style={{ direction: 'ltr', textAlign: 'right' }}
                value={values.contactPhoneNumber ?? ''}
                disabled={disableEditing}
                label="טלפון איש קשר"
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.contactPhoneNumber}
                hasError={!!errors.contactPhoneNumber && touched.contactPhoneNumber}
              />
              <Input
                name="contactTitle"
                type="text"
                value={values.contactTitle ?? ''}
                disabled={disableEditing}
                label="תיאור/תפקיד איש קשר"
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.contactTitle}
                hasError={!!errors.contactTitle && touched.contactTitle}
              />
            </RowContainer>

            <RowContainer>
              <Input
                name="totalPackages"
                type="number"
                value={values.totalPackages}
                disabled={disableEditing}
                label="מספר אריזות"
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.totalPackages}
                hasError={!!errors.totalPackages && touched.totalPackages}
              />
            </RowContainer>

            <RowContainer>
              <Textarea
                name="internalNotes"
                rows={3}
                label="הערות פנימיות (מוצג למנהלים בלבד)"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.internalNotes ?? ''}
                disabled={disableEditing}
                errorMessage={errors.internalNotes}
                hasError={!!errors.internalNotes && touched.internalNotes}
              />
            </RowContainer>

            <RowContainer>
              <Input
                name="oneTime"
                type="text"
                value={values.oneTime ?? ''}
                disabled={disableEditing}
                label="לחלק חד פעמית? אם כן, מתי?"
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors.oneTime}
                hasError={!!errors.oneTime && touched.oneTime}
              />
            </RowContainer>

            {!disableEditing && (
              <ActionButtonsWrapper>
                <ActionButton title="שמירה" kind="primary" type="submit" disabled={isSubmitting} />
              </ActionButtonsWrapper>
            )}
          </form>
        )}
      </Formik>
    </>
  );
};
