import { Route } from '../../../@types/routes';
import { RouteListItem } from './route-list-item';

type RoutesListProps = {
  routes: Route[];
  onRouteClick: (route: Route) => void;
};

export const RoutesList = ({ routes, onRouteClick }: RoutesListProps) => {
  if (routes.length <= 0) {
    return <div>אין מסלולים להצגה</div>;
  }

  return (
    <>
      {routes.map((route) => {
        return (
          <RouteListItem
            onClick={() => {
              onRouteClick(route);
            }}
            key={route.id}
            route={route}
          />
        );
      })}
    </>
  );
};
