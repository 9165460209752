import { useState } from 'react';
import { ActionButton } from '../../../components/action-button';
import { Modal } from '../../../components/modal';
import { DefaultRoute } from '../../../@types/default-routes';
import { SaveDefaultRoutePayload } from '../../../queries/default-routes';
import { ButtonsRow, CustomInput, ErrorMessage } from './style';

type EditProps = {
  isEdit: true;
  route: DefaultRoute;
  onSaveClick: (payload: SaveDefaultRoutePayload) => void;
  onCancel: () => void;
};

type AddProps = {
  isEdit: false;
  onSaveClick: (payload: Omit<SaveDefaultRoutePayload, 'routeId'>) => void;
  onCancel: () => void;
};

type DefaultRouteEditorModalProps = AddProps | EditProps;

export const DefaultRouteEditorModal = (props: DefaultRouteEditorModalProps) => {
  const { onCancel, onSaveClick, isEdit } = props;

  const [routeName, setRouteName] = useState<string>(isEdit ? props.route.name : '');

  const [errorMessage, setErrorMessage] = useState('');

  const handleRouteNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRouteName(event.currentTarget.value);
  };

  const handleSaveClick = () => {
    setErrorMessage('');

    if (!routeName) {
      setErrorMessage('שם המסלול לא יכול להיות ריק');

      return;
    }

    if (isEdit) {
      onSaveClick({
        routeId: props.route.id,
        name: routeName,
      });
    } else {
      onSaveClick({
        name: routeName,
      });
    }
  };

  return (
    <Modal title="פרטי המסלול" isVisible onClose={onCancel}>
      <form
        onSubmit={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleSaveClick();
        }}
      >
        <CustomInput label="שם המסלול" value={routeName} onChange={handleRouteNameChange} />

        {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

        <ButtonsRow>
          <ActionButton title={isEdit ? 'שמירה' : 'הוספה'} type="submit" />
          <ActionButton title="ביטול" onClick={onCancel} kind="secondary" />
        </ButtonsRow>
      </form>
    </Modal>
  );
};
