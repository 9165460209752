import { ExtendedAddress } from '../../../../@types/address';
import { NewBadge, PackagesBadge } from '../../../../components/badges';
import { OneTimeBadge, RouteName, RoutesWrapper, Title, Wrapper } from './style';

type AddressListItemProps = {
  address: ExtendedAddress;
  onClick: () => void;
};

export const AddressListItem = ({ address, onClick }: AddressListItemProps) => {
  const {
    street,
    buildingNumber,
    totalPackages,
    status,
    entrance,
    apartmentNumber,
    oneTime,
    active,
    _isNewAddress,
  } = address;

  const buildingEntrance = entrance ?? '';
  const apartment = apartmentNumber ? `(דירה ${apartmentNumber})` : '';

  const title = `${street} ${buildingNumber} ${buildingEntrance} ${apartment}`;

  const renderPackagesCounter = () => {
    if (totalPackages <= 1) {
      return null;
    }

    return <PackagesBadge>{totalPackages} אריזות</PackagesBadge>;
  };

  const renderOneTimeBadge = () => {
    if (!oneTime) {
      return null;
    }

    return <OneTimeBadge>חד פעמי: {oneTime}</OneTimeBadge>;
  };

  return (
    <Wrapper onClick={onClick} done={!!status}>
      <Title isActive={active}>{title}</Title>

      {_isNewAddress && <NewBadge>חדש</NewBadge>}
      {renderOneTimeBadge()}
      {renderPackagesCounter()}

      {!!address.routes && (
        <RoutesWrapper>
          {address.routes.split(',').map((routeName) => {
            return <RouteName key={routeName}>{routeName}</RouteName>;
          })}
        </RoutesWrapper>
      )}
    </Wrapper>
  );
};
