import styled from 'styled-components';
import { COLORS } from '../../../consts/colors';
import { APARTMENT_STATUS } from '../../../@types/address';

const { EXTRA_LIGHT_GRAY, LIGHT_BLUE, DARKER_GRAY } = COLORS;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  background-color: ${EXTRA_LIGHT_GRAY};
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;

  & > button {
    flex: 1;
  }
`;

export const PhoneLink = styled.a`
  color: inherit;
  font: inherit;
  text-decoration: none;

  &:before {
    content: '📞';
    display: inline-block;
    font-size: 14px;
    margin-right: 3px;
  }

  &:hover {
    opacity: 0.9;
  }
`;

export const InternalContactInfo = styled.div`
  border: 1px solid ${COLORS.DARK_GRAY};
  margin-top: 25px;
  border-radius: 3px;
  background-color: ${COLORS.EXTRA_LIGHT_GRAY};
`;

export const InternalContactTitle = styled.div`
  font-size: 24px;
  text-decoration: underline;
`;

export const StatusResult = styled.div<{ status: APARTMENT_STATUS }>`
  background-image: linear-gradient(
    to left,
    ${(props) => (props.status === APARTMENT_STATUS.OPENED ? LIGHT_BLUE : DARKER_GRAY)},
    ${EXTRA_LIGHT_GRAY}
  );
  color: white;
  border: 0;
  box-shadow: none;
  padding: 20px;
  width: 100%;
  font-family: 'NextExitRound', Arial;
  font-size: 26px;
  line-height: 22px;
  flex: 2 !important;
`;

export const ResetStatusButton = styled.button`
  border: none;
  background: none;
  color: ${COLORS.DARKER_GRAY};
  font-size: 28px;
  text-decoration: underline;
  cursor: pointer;
  font-family: 'NextExitRound', Arial;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-left: 20px;

  :disabled {
    opacity: 0.6;
  }
`;
