import { SCREENS } from "../consts/screens";

export const getAddressLocatorRoute = (routeId: string, addressId: string) => {
  return SCREENS.ADDRESS_LOCATOR.replace(':routeId', routeId).replace(':addressId', addressId);
};

export const getEditAddressRoute = (addressId: string) => {
  return SCREENS.EDIT_ADDRESS.replace(':addressId', addressId);
};

export const getRoutePeople = (driver?: string, passengers?: string) => {
  let people = '';

  if (driver) {
    people = driver;
  }

  if (passengers) {
    if (people) {
      people += ' + ';
    }

    people += passengers;
  }

  return people;
};

export const getDefaultRouteRoute = (routeId: string) => {
  return SCREENS.DEFAULT_ROUTE.replace(':routeId', routeId);
};
