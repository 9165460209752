import { useState } from 'react';
import { Input } from '../input';
import { Modal } from '../modal';
import { useAddresses } from '../../queries/address';
import { shouldFilterByQuery } from '../../utils/addresses';
import { ActionButton } from '../action-button';
import { ExtendedAddress } from '../../@types/address';
import { AddressesList } from './addresses-list';
import { ButtonsRow, ListContainer, Wrapper } from './style';

type LinkAddressesModalProps = {
  filterAddresses?: string[];
  onSave: (selectedIds: string[]) => void;
  onCancel: () => void;
};

export const LinkAddressesModal = ({
  onSave,
  onCancel,
  filterAddresses = [],
}: LinkAddressesModalProps) => {
  const [query, setQuery] = useState('');
  const [selectedAddressesIds, setSelectedAddressesIds] = useState<string[]>([]);

  const { data, isLoading } = useAddresses();
  const allAddresses = data?.addresses || [];

  const handleFilterQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.currentTarget.value);
  };

  const toggleAddressSelect = (address: ExtendedAddress) => {
    const newSelectedAddresses = [...selectedAddressesIds];

    const itemIndex = newSelectedAddresses.findIndex((selectedItem) => {
      return selectedItem === address.id;
    });

    if (itemIndex >= 0) {
      newSelectedAddresses.splice(itemIndex, 1);
    } else {
      newSelectedAddresses.push(address.id);
    }

    setSelectedAddressesIds(newSelectedAddresses);
  };

  const addressesList =
    allAddresses
      .filter((address) => {
        // hide non-active addresses
        return address.active;
      })
      .filter((address) => {
        return !filterAddresses.includes(address.id);
      })
      .filter((address) => {
        return shouldFilterByQuery(address, query);
      }) || [];

  // show addresses without routes first
  addressesList.sort((a, b) => {
    if (!a.routes) {
      return -1;
    }

    if (!b.routes) {
      return 1;
    }

    return 0;
  });

  return (
    <Modal title="שיוך כתובות למסלול" isVisible onClose={onCancel}>
      <Wrapper>
        <Input value={query} placeholder="סינון" onChange={handleFilterQueryChange} />

        {/* todo: loading indicator */}
        {isLoading && 'Loading'}

        <ListContainer>
          <AddressesList
            addresses={addressesList}
            onAddressClick={toggleAddressSelect}
            selectedIds={selectedAddressesIds}
          />
        </ListContainer>

        <ButtonsRow>
          <ActionButton
            title={`שיוך (${selectedAddressesIds.length})`}
            type="submit"
            onClick={() => {
              onSave(selectedAddressesIds);
            }}
            disabled={selectedAddressesIds.length <= 0}
          />
          <ActionButton title="ביטול" onClick={onCancel} kind="secondary" />
        </ButtonsRow>
      </Wrapper>
    </Modal>
  );
};
