import { memo } from 'react';
import { Modal } from '../../../components/modal';
import { BlessingMessage, ImageContainer } from './style';

const IMAGES = [
  require('../../../assets/team/1.jpg'),
  require('../../../assets/team/2.jpeg'),
  require('../../../assets/team/3.jpeg'),
  require('../../../assets/team/4.jpeg'),
  require('../../../assets/team/5.jpeg'),
  require('../../../assets/team/6.jpeg'),
  require('../../../assets/team/7.jpeg'),
  require('../../../assets/team/8.jpg'),
  require('../../../assets/team/9.jpeg'),
  require('../../../assets/team/10.jpg'),
  require('../../../assets/team/11.jpg'),
  require('../../../assets/team/12.jpg'),
  require('../../../assets/team/13.jpg'),
  require('../../../assets/team/14.jpg'),
];

type RouteEndedScreenProps = {
  onHide: () => void;
};

const RouteEndedScreen = ({ onHide }: RouteEndedScreenProps) => {
  const imageId = Math.floor(Math.random() * IMAGES.length);

  return (
    <Modal isVisible title="החלוקה הסתיימה" onClose={onHide}>
      <ImageContainer
        style={{
          backgroundImage: `url(${IMAGES[imageId]})`,
          width: '100%',
          maxWidth: 350,
          height: 350,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          margin: '0 auto',
        }}
      />

      <BlessingMessage>תודה!</BlessingMessage>
    </Modal>
  );
};

// memo to prevent reloading when the screen at the background is refetching/reloading
export default memo(RouteEndedScreen);
