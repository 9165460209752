import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { APARTMENT_STATUS, Address } from '../../../@types/address';
import { EditIcon } from '../../../assets/svgs';
import { ActionButton } from '../../../components/action-button';
import { COLORS } from '../../../consts/colors';
import { RouteNotActiveWarning } from '../../../components/not-active-warning';
import { AddressDetail } from './address-detail';
import {
  ActionButtonsWrapper,
  InternalContactInfo,
  InternalContactTitle,
  PhoneLink,
  ResetStatusButton,
  StatusResult,
} from './style';
import { PackageStatusModal } from './package-status-modal';

type AddressProps = {
  data: Address;
  showMap: boolean;
  onStatusChange: (
    payload: {
      newStatus: APARTMENT_STATUS | null;
      wasPackageLeft: boolean;
    },
    onSuccess?: () => void,
  ) => void;
  reloadData: () => void;
  isUpdatingAddressStatus: boolean;
  isRouteActive?: boolean;
};

export const AddressView = ({
  data,
  showMap,
  onStatusChange,
  reloadData,
  isUpdatingAddressStatus,
  isRouteActive = false,
}: AddressProps) => {
  const navigate = useNavigate();

  const [showPackageStatusModal, setPackageStatusModalVisibility] = useState(false);

  const handleNotOpenedClick = () => {
    setPackageStatusModalVisibility(true);
  };

  const handleClosePackageStatusModal = () => {
    setPackageStatusModalVisibility(false);
  };

  const handleLeavePackageSelected = () => {
    onStatusChange({ newStatus: APARTMENT_STATUS.NOT_OPENED, wasPackageLeft: true }, () => {
      navigate(-1);
    });
  };

  const handleTakePackageSelected = () => {
    onStatusChange({ newStatus: APARTMENT_STATUS.NOT_OPENED, wasPackageLeft: false }, () => {
      navigate(-1);
    });
  };

  const handleOpenedClick = () => {
    onStatusChange({ newStatus: APARTMENT_STATUS.OPENED, wasPackageLeft: true }, () => {
      navigate(-1);
    });
  };

  const handleResetClick = () => {
    onStatusChange({ newStatus: null, wasPackageLeft: false }, () => {
      reloadData();
    });
  };

  const renderInternalContactInfo = () => {
    if (!data.contactName && !data.contactPhoneNumber) {
      return;
    }

    return (
      <InternalContactInfo>
        <InternalContactTitle>אנשי קשר מסח״י</InternalContactTitle>
        <AddressDetail title="שם">{data.contactName}</AddressDetail>
        <AddressDetail title="טלפון" isLTR>
          {data.contactPhoneNumber && (
            <PhoneLink href={`tel:${data.contactPhoneNumber}`}>{data.contactPhoneNumber}</PhoneLink>
          )}
        </AddressDetail>
      </InternalContactInfo>
    );
  };

  const renderAddress = () => {
    const buildingEntrance = data.entrance ? `- כניסה ${data.entrance}` : '';

    return (
      <>
        {data.totalPackages > 1 && (
          <AddressDetail title="מספר אריזות">{data.totalPackages}</AddressDetail>
        )}
        <AddressDetail title="רחוב">
          {data.street} {data.buildingNumber} {buildingEntrance}
        </AddressDetail>
        <AddressDetail title="הגעה לכתובת">{data.addressInstructions}</AddressDetail>
        <AddressDetail title="קוד" isLTR>
          {data.code}
        </AddressDetail>
        <AddressDetail title="קומה">{data.floor}</AddressDetail>
        <AddressDetail title="דירה">{data.apartmentNumber}</AddressDetail>
        <AddressDetail title="הגעה לדירה">{data.apartmentNotes}</AddressDetail>
        <AddressDetail title="שם משפחה">{data.familyName}</AddressDetail>
        <AddressDetail title="טלפון" isLTR>
          {data.familyPhoneNumber && (
            <PhoneLink href={`tel:${data.familyPhoneNumber}`}>{data.familyPhoneNumber}</PhoneLink>
          )}
        </AddressDetail>
        <AddressDetail title="הערות">{data.generalNotes}</AddressDetail>

        {renderInternalContactInfo()}
      </>
    );
  };

  const renderMap = () => {
    return (
      <iframe
        title="Map"
        style={{ display: 'block', width: '100%', height: 450, border: 0, margin: '20px auto' }}
        loading="lazy"
        allowFullScreen
        src={`https://www.google.com/maps/embed/v1/place?q=${data.street} ${data.buildingNumber}, ${data.city}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&language=iw`}
      ></iframe>
    );
  };

  const renderResetStatusButton = () => {
    return (
      <ResetStatusButton
        onClick={handleResetClick}
        disabled={isUpdatingAddressStatus || !isRouteActive}
      >
        <EditIcon width={24} fill={COLORS.DARKER_GRAY} style={{ marginLeft: 5 }} />
        שינוי
      </ResetStatusButton>
    );
  };

  const renderAddressStatus = () => {
    if (data.status === APARTMENT_STATUS.OPENED) {
      return (
        <>
          <StatusResult status={APARTMENT_STATUS.OPENED}>פתחו!&nbsp;&nbsp;&nbsp;🎉</StatusResult>
          {renderResetStatusButton()}
        </>
      );
    } else if (data.status === APARTMENT_STATUS.NOT_OPENED) {
      const packageText = data.wasPackageLeft ? 'והשארנו' : 'ולא השארנו';

      return (
        <>
          <StatusResult status={APARTMENT_STATUS.NOT_OPENED}>
            {`לא פתחו ${packageText}`}
          </StatusResult>
          {renderResetStatusButton()}
        </>
      );
    }

    return (
      <>
        <ActionButton
          title="פתחו"
          kind="primary"
          onClick={handleOpenedClick}
          disabled={isUpdatingAddressStatus || !isRouteActive}
        />
        <ActionButton
          title="לא פתחו"
          kind="secondary"
          onClick={handleNotOpenedClick}
          disabled={isUpdatingAddressStatus || !isRouteActive}
        />
      </>
    );
  };

  return (
    <>
      <div style={{ width: '95%', margin: '10px auto 0 auto', paddingBottom: 100 }}>
        {!isRouteActive && <RouteNotActiveWarning />}
        <div style={{ marginTop: '20px' }}>{showMap ? renderMap() : renderAddress()}</div>
      </div>

      <ActionButtonsWrapper>{renderAddressStatus()}</ActionButtonsWrapper>

      <PackageStatusModal
        isVisible={showPackageStatusModal}
        onLeavePackageSelected={handleLeavePackageSelected}
        onTakePackageSelected={handleTakePackageSelected}
        onClose={handleClosePackageStatusModal}
      />
    </>
  );
};
