import styled from 'styled-components';
import { IMAGES } from '../../assets/images';
import { COLORS } from '../../consts/colors';
import { Input } from '../../components/input';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 90vh;
`;

export const PageForm = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 500px;
  margin: 0 auto;
`;

export const Logo = styled.img.attrs({
  src: IMAGES.LOGO,
})`
  width: 70%;
  max-width: 300px;
  justify-self: center;
  margin-bottom: 50px;
`;

export const Separator = styled.div`
  margin: 50px auto;
  position: relative;
  width: 100%;
  height: 1px;
  border-top: 1px solid ${COLORS.LIGHT_GRAY};
  text-align: center;

  :before {
    content: 'או';
    position: absolute;
    top: -10px;
    right: 50%;
    margin-right: -25px;
    background-color: ${COLORS.WHITE};
    padding: 0 20px;
    color: ${COLORS.DARKER_GRAY};
    font-weight: 400;
  }
`;

export const CustomInput = styled(Input)<{
  direction?: 'ltr' | 'rtl';
}>`
  margin-bottom: 20px;
  direction: ${({ direction = 'ltr' }) => direction};
  text-align: right;
`;

export const ErrorMessage = styled.div`
  color: ${COLORS.LIGHT_RED};
  font-weight: bold;
`;
