import { NotesIcon } from '../../assets/svgs';
import { COLORS } from '../../consts/colors';
import { Button } from './style';

export const FAB_BOTTOM_SPACING = 80;

type NotesFABProps = {
  onClick: () => void;
};

export const NotesFAB = ({ onClick }: NotesFABProps) => {
  return (
    <Button onClick={onClick}>
      <NotesIcon height={60} fill={COLORS.WHITE} style={{ padding: '10px 8px 7px 9px' }} />
    </Button>
  );
};
