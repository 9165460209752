import { CustomInput, ErrorMessage, LabelText, LabelWrapper } from './style';

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  hasError?: boolean;
  errorMessage?: string;
};

export const Input = ({ label, hasError = false, errorMessage, ...inputProps }: InputProps) => {
  return (
    <LabelWrapper>
      <LabelText>{label}</LabelText>
      <CustomInput {...inputProps} hasError={hasError} />
      {hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </LabelWrapper>
  );
};
