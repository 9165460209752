import Lottie from 'lottie-react';
import { memo } from 'react';
import BlueHeart from '../../../assets/lottie/blue-heart.json';
import BlueTrophy from '../../../assets/lottie/blue-trophy.json';
import HeartBox from '../../../assets/lottie/heart-box.json';
// import HeartGift from '../../../assets/lottie/heart-gift.json';
import GivingHeart from '../../../assets/lottie/giving-heart.json';
import { Modal } from '../../../components/modal';
import {
  BackToAddresses,
  BlessingMessage,
  RouteEndedMessage,
  SahiSentence,
  Wrapper,
} from './style';

const LOTTIE = [
  <Lottie animationData={BlueHeart} style={{ height: 230 }} />,
  <Lottie animationData={BlueTrophy} style={{ height: 250 }} />,
  <Lottie animationData={GivingHeart} style={{ height: 170, margin: '20px 0' }} />,
  <Lottie animationData={HeartBox} style={{ height: 150, margin: '40px 0' }} />,
  //   <Lottie animationData={HeartGift} style={{ height: 150, margin: '30px 0' }} />,
];

const ROUTE_ENDED_MESSAGES = [
  'כל החבילות נמסרו',
  'זהו? נגמר? אלופיםםםםם!',
  'פייי כבר סיימתם? אין כמוכם!',
];

const SAHI_SENTENCES = [
  <>
    הדבר הכי גדול בעולם
    <br />
    זה לעשות טוב למישהו
  </>,
  'אני אחיך אל תשכח',
  'אוהבים אותך!',
  'נתינה זו דרך חיים',
  'חסדים עליכם',
];

type RouteEndedScreenProps = {
  onHide: () => void;
};

const RouteEndedScreen = ({ onHide }: RouteEndedScreenProps) => {
  const randomEndingMessageIndex = Math.floor(Math.random() * ROUTE_ENDED_MESSAGES.length);
  const randomLottieIndex = Math.floor(Math.random() * LOTTIE.length);
  const randomSentenceIndex = Math.floor(Math.random() * SAHI_SENTENCES.length);

  return (
    <Modal isVisible>
      <Wrapper>
        <RouteEndedMessage>{ROUTE_ENDED_MESSAGES[randomEndingMessageIndex]}</RouteEndedMessage>
        <BlessingMessage>תודה!</BlessingMessage>
        {LOTTIE[randomLottieIndex]}
        <SahiSentence>{SAHI_SENTENCES[randomSentenceIndex]}</SahiSentence>

        <BackToAddresses onClick={onHide}>בחזרה לרשימת הכתובות</BackToAddresses>
      </Wrapper>
    </Modal>
  );
};

// memo to prevent reloading when the screen at the background is refetching/reloading
export default memo(RouteEndedScreen);
