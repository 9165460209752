import { Distribution } from "../../@types/distributions";

type DistributionResponse = {
  id: string;
  name: string;
  createTime: string;
  isActive: boolean;
}[];

export const formatGetAllDistributions = (response: DistributionResponse): Distribution[] => {
  return response.map((distribution) => {
    return {
      id: distribution.id,
      name: distribution.name,
      createTime: new Date(distribution.createTime),
      isActive: distribution.isActive,
    };
  });
};
