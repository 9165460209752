import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useSnackbar } from 'react-simple-snackbar';
import { AddressLocatorScreen } from './screens/address-locator';
import { RouteOverviewScreen } from './screens/route-overview';
import { RoutesScreen } from './screens/routes';
import { DistributionsScreen } from './screens/distributions';
import { AddressEditorScreen } from './screens/address-editor';
import { SCREENS } from './consts/screens';
import { AuthProvider } from './providers/auth';
import { RouteEditorScreen } from './screens/route-editor';
import { ProtectedRoute } from './components/protected-route';
import { PERMISSION } from './consts/permissions';
import { HomeScreen } from './screens/home';
import { RoutesEditorScreen } from './screens/routes-editor';
import { axiosInstance } from './api';
import { COLORS } from './consts/colors';
import { HTTP_RESPONSE_CODE } from './consts/http';
import { AddressCreateScreen } from './screens/address-creator';
import { DistributionsEditorScreen } from './screens/distributions-editor';
import { AllAddressesListScreen } from './screens/all-addresses';
import { DefaultRoutesEditorScreen } from './screens/default-routes-editor';
import { DefaultRouteEditorScreen } from './screens/default-route-editor';
import { StatisticsScreen } from './screens/statistics';
import { UsersScreen } from './screens/users';

const App = () => {
  const [openSnackbar] = useSnackbar({
    position: 'bottom-left',
    style: {
      backgroundColor: COLORS.LIGHT_RED,
      textAlign: 'right',
      fontFamily: 'NextExitRound',
    },
  });

  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: AxiosError) => {
        const responseCode = error.response?.status;

        if (
          responseCode !== HTTP_RESPONSE_CODE.FORBIDDEN &&
          responseCode !== HTTP_RESPONSE_CODE.UNAUTHORIZED
        ) {
          switch (responseCode) {
            case HTTP_RESPONSE_CODE.BAD_REQUEST: {
              openSnackbar(`הפרטים שהוזנו שגויים`);
              break;
            }
            default: {
              const errorCode = responseCode ?? 'אין תגובה מהשרת';
              openSnackbar(`הבקשה נכשלה. שגיאה: ${errorCode}`);
            }
          }
        }

        return Promise.reject(error);
      },
    );

    return () => {
      axiosInstance.interceptors.response.eject(interceptor);
    };
  });

  return (
    <AuthProvider>
      <Routes>
        <Route
          path={SCREENS.DEFAULT_ROUTES}
          element={
            <ProtectedRoute requiredPermissions={[PERMISSION.EDIT_DEFAULT_ROUTES]}>
              <DefaultRoutesEditorScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path={SCREENS.DEFAULT_ROUTE}
          element={
            <ProtectedRoute requiredPermissions={[PERMISSION.EDIT_DEFAULT_ROUTES]}>
              <DefaultRouteEditorScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path={SCREENS.ALL_ADDRESSES}
          element={
            <ProtectedRoute requiredPermissions={[PERMISSION.EDIT_ADDRESSES]}>
              <AllAddressesListScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path={SCREENS.EDIT_ADDRESS}
          element={
            <ProtectedRoute requiredPermissions={[PERMISSION.EDIT_ADDRESSES]}>
              <AddressEditorScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path={SCREENS.ADDRESS_LOCATOR}
          element={
            <ProtectedRoute requiredPermissions={[PERMISSION.VIEW_ADDRESSES]}>
              <AddressLocatorScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/distribution/:distributionId/route/:routeId/edit"
          element={
            <ProtectedRoute requiredPermissions={[PERMISSION.EDIT_ROUTES]}>
              <RouteEditorScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/distribution/:distributionId/route/:routeId"
          element={
            <ProtectedRoute requiredPermissions={[PERMISSION.VIEW_ROUTES]}>
              <RouteOverviewScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path={SCREENS.CREATE_ADDRESS}
          element={
            <ProtectedRoute requiredPermissions={[PERMISSION.CREATE_ADDRESSES]}>
              <AddressCreateScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/distribution/:distributionId/routes/edit"
          element={
            <ProtectedRoute requiredPermissions={[PERMISSION.EDIT_ROUTES]}>
              <RoutesEditorScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/distribution/:distributionId/routes"
          element={
            <ProtectedRoute requiredPermissions={[PERMISSION.VIEW_ROUTES]}>
              <RoutesScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path={SCREENS.DISTRIBUTIONS}
          element={
            <ProtectedRoute requiredPermissions={[PERMISSION.VIEW_DISTRIBUTIONS]}>
              <DistributionsScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path={SCREENS.EDIT_DISTRIBUTIONS}
          element={
            <ProtectedRoute requiredPermissions={[PERMISSION.EDIT_DISTRIBUTIONS]}>
              <DistributionsEditorScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path={SCREENS.STATISTICS}
          element={
            <ProtectedRoute requiredPermissions={[PERMISSION.VIEW_STATISTICS]}>
              <StatisticsScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path={SCREENS.USERS}
          element={
            <ProtectedRoute requiredPermissions={[PERMISSION.VIEW_USERS]}>
              <UsersScreen />
            </ProtectedRoute>
          }
        />
        <Route path={SCREENS.HOME} element={<HomeScreen />} />
      </Routes>
    </AuthProvider>
  );
};

export default App;
