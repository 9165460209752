import styled from 'styled-components';
import { COLORS } from '../../consts/colors';

export const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
  background-color: ${COLORS.EXTRA_LIGHT_GRAY};
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;

  & > button {
    flex: 1;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 10px;

  @media (max-width: 700px) {
    & label {
      flex: 1 0 170px;
    }
    flex-wrap: wrap;
  }
`;
