import { PERMISSION } from '../consts/permissions';
import { ENDPOINTS } from './endpoints';
import { axiosInstance } from '.';

export type LoginResponse = {
  accessToken: string;
}

export type MeResponse = {
  permissions: PERMISSION[];
}

export const login = async (
  username: string,
  password: string,
): Promise<LoginResponse> => {
  const response = await axiosInstance.post(ENDPOINTS.LOGIN, {
    username,
    password,
  });

  return response.data as LoginResponse;
};

export const me = async (): Promise<MeResponse> => {
  const response = await axiosInstance.get(ENDPOINTS.ME);

  return response.data as MeResponse;
};

export const logout = async () => {
  await axiosInstance.post('/logout.php');
};
