import { User } from "../../@types/users";

type UserResponse = {
  id: string;
  firstName: string;
  lastName: string;
  lastLoginTime?: Date | null;
  lastActivityTime?: Date | null;
  role: {
    id: string,
    name: string,
  }
}[];

export const formatGetAllUsers = (response: UserResponse): User[] => {
  return response.map((user) => {
    return {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      lastLoginTime: user.lastLoginTime ? new Date(user.lastLoginTime) : null,
      lastActivityTime: user.lastActivityTime ? new Date(user.lastActivityTime) : null,
      role: user.role,
    };
  });
};
