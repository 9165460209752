import { PERMISSION } from "../consts/permissions";
import { useAuth } from "../providers/auth/contexts";

export const useAuthPermissions = () => {
  const auth = useAuth();

  return {
    hasPermissions: (requiredPermissions: PERMISSION[]) => {
      return requiredPermissions.every((requiredPermission) => {
        return auth.permissions.includes(requiredPermission);
      });
    },
  };
};
