import { Distribution } from '../../../@types/distributions';
import { EditableDistributionListItem } from './editable-distribution-list-item';

type DistributionsListProps = {
  distributions: Distribution[];
  onDistributionClick: (distribution: Distribution) => void;
  onToggleDistributionSelect: (distribution: Distribution) => void;
  selectedDistributions?: string[];
};

export const DistributionsList = ({
  distributions,
  onDistributionClick,
  selectedDistributions,
  onToggleDistributionSelect,
}: DistributionsListProps) => {
  if (distributions.length <= 0) {
    return <div>אין חלוקות</div>;
  }

  return (
    <>
      {distributions.map((distribution) => {
        return (
          <EditableDistributionListItem
            onClick={() => {
              onDistributionClick(distribution);
            }}
            key={distribution.id}
            isSelected={selectedDistributions?.includes(distribution.id) || false}
            distribution={distribution}
            onToggleSelect={() => {
              onToggleDistributionSelect(distribution);
            }}
          />
        );
      })}
    </>
  );
};
