import styled, { keyframes } from 'styled-components';
import { COLORS } from '../../consts/colors';
import { IMAGES } from '../../assets/images';

const rotationAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled.div`
  display: block;
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoaderIndicator = styled.span`
  width: 200px;
  height: 200px;
  border: 3px solid ${COLORS.DARK_BLUE};
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  box-sizing: border-box;
  animation: ${rotationAnimation} 1s linear infinite;

  &:after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-bottom-color: ${COLORS.LIGHT_BLUE};
  }
`;

export const Logo = styled.img.attrs({
  src: IMAGES.LOGO,
})`
  width: 150px;
  justify-self: center;
  margin-top: 20px;
`;
