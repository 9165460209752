import { useState } from 'react';
import { Modal } from '../../../components/modal';
import { ActionButton } from '../../../components/action-button';
import { Textarea } from '../../../components/Textarea';
import { useSetDistributionNotes } from '../../../queries/distributions';
import { ButtonsRow } from './style';

type NotesModalProps = {
  distributionId: string;
  defaultValue?: string;
  onNotesEdited: (value: string) => void;
  onCancel: () => void;
};

export const NotesModal = ({
  distributionId,
  defaultValue = '',
  onNotesEdited,
  onCancel,
}: NotesModalProps) => {
  const [value, setValue] = useState<string>(defaultValue);

  const { mutate: mutateSetNotes, isLoading } = useSetDistributionNotes();

  const handleValueChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.currentTarget.value);
  };

  const handleSaveClick = () => {
    mutateSetNotes(
      {
        distributionId,
        notes: value,
      },
      {
        onSuccess: () => {
          onNotesEdited(value);
        },
      },
    );
  };

  return (
    <Modal title="רִישׁוּמִישׁוּגִי" isVisible onClose={onCancel}>
      <form
        onSubmit={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleSaveClick();
        }}
      >
        <Textarea
          placeholder='כל מה שרוצים לרשום כדי לא לשכוח בנוגע לחלוקה ע"ש אבישג (שוגי) האלופה'
          value={value}
          onChange={handleValueChange}
          rows={8}
        />

        <ButtonsRow>
          <ActionButton title="שמירה" type="submit" disabled={isLoading} />
          <ActionButton title="ביטול" onClick={onCancel} kind="secondary" />
        </ButtonsRow>
      </form>
    </Modal>
  );
};
