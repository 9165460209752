import { ActionButtonType, Link } from './style';

type ActionLinkProps = {
  title: string;
  href: string;
  kind?: ActionButtonType;
  disabled?: boolean;
};

export const ActionLink = ({
  title,
  href,
  kind = 'primary',
  disabled = false,
}: ActionLinkProps) => {
  return (
    <Link href={href} buttonType={kind} isDisabled={disabled}>
      {title}
    </Link>
  );
};
