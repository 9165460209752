import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header, HeaderButton } from '../../components/header';
import { MergeIcon } from '../../assets/svgs';
import { PromptModal } from '../../components/prompt-modal';
import { AlertModal } from '../../components/alert-modal';
import {
  SaveDefaultRoutePayload,
  useCreateDefaultRoute,
  useDefaultRoutes,
  useMergeDefaultRoutes,
  useSetDefaultRouteInfo,
} from '../../queries/default-routes';
import { DefaultRoute } from '../../@types/default-routes';
import { getDefaultRouteRoute } from '../../utils/routes';
import { AddFAB, FAB_BOTTOM_SPACING } from '../../components/add-fab';
import { PERMISSION } from '../../consts/permissions';
import { useAuthPermissions } from '../../hooks/auth';
import { RoutesList } from './routes-list';
import { DefaultRouteEditorModal } from './default-route-editor-modal';

export const DefaultRoutesEditorScreen = () => {
  const navigate = useNavigate();
  const [selectedRoutes, setSelectedRoutes] = useState<string[]>([]);
  const [routeToEdit, setRouteToEdit] = useState<DefaultRoute | null>(null);

  const [isPromptMergeRoutesModalVisible, setPromptMergeRoutesModalVisibility] = useState(false);
  const [isAlertMergeRoutesModalVisible, setAlertMergeRoutesModalVisibility] = useState(false);
  const [isCreateRoutesModalVisible, setCreateRoutesModalVisibility] = useState(false);

  const { hasPermissions } = useAuthPermissions();

  const { isFetching, data, refetch: refetchRoutesList } = useDefaultRoutes();
  const { mutate: mutateDefaultRouteInfo, isLoading: isUpdating } = useSetDefaultRouteInfo();
  const { mutate: mutateMergeDefaultRoutes, isLoading: isMerging } = useMergeDefaultRoutes();
  const { mutate: mutateCreateDefaultRoute, isLoading: isCreating } = useCreateDefaultRoute();

  const toggleRouteSelect = (route: DefaultRoute) => {
    const newSelectedRoutes = [...selectedRoutes];

    const itemIndex = newSelectedRoutes.findIndex((selectedItem) => {
      return selectedItem === route.id;
    });

    if (itemIndex >= 0) {
      newSelectedRoutes.splice(itemIndex, 1);
    } else {
      newSelectedRoutes.push(route.id);
    }

    setSelectedRoutes(newSelectedRoutes);
  };

  const handleAddRouteClick = () => {
    setCreateRoutesModalVisibility(true);
  };

  const handleCancelAddRouteClick = () => {
    setCreateRoutesModalVisibility(false);
  };

  const handleSaveAddRouteClick = (routePayload: Omit<SaveDefaultRoutePayload, 'routeId'>) => {
    setCreateRoutesModalVisibility(false);

    mutateCreateDefaultRoute(
      {
        routeName: routePayload.name,
      },
      {
        onSuccess: () => {
          refetchRoutesList();
        },
      },
    );
  };

  const handleMergeClick = () => {
    if (selectedRoutes.length < 2) {
      setAlertMergeRoutesModalVisibility(true);

      return;
    }

    setPromptMergeRoutesModalVisibility(true);
  };

  const handleConfirmMergeAlertClick = () => {
    setAlertMergeRoutesModalVisibility(false);
  };

  const handleSaveMergeClick = (newRouteName: string) => {
    setPromptMergeRoutesModalVisibility(false);

    mutateMergeDefaultRoutes(
      {
        newRouteName,
        routesIds: selectedRoutes,
      },
      {
        onSuccess: () => {
          setSelectedRoutes([]);
          refetchRoutesList();
        },
      },
    );
  };

  const handleCancelMergeClick = () => {
    setPromptMergeRoutesModalVisibility(false);
  };

  const handleEditRouteClick = (route: DefaultRoute) => {
    setRouteToEdit(route);
  };

  const handleSaveEditRouteClick = (routePayload: SaveDefaultRoutePayload) => {
    setRouteToEdit(null);

    mutateDefaultRouteInfo(routePayload, {
      onSuccess: () => {
        refetchRoutesList();
      },
    });
  };

  const renderScreenTitle = () => {
    if (selectedRoutes.length > 0) {
      return `נבחרו: ${selectedRoutes.length}`;
    }

    return 'מסלולים קבועים';
  };

  const renderActionButtons = () => {
    const buttons: HeaderButton[] = [];

    if (selectedRoutes.length > 0) {
      buttons.push({ title: 'איחוד', icon: <MergeIcon />, onclick: handleMergeClick });
    }

    return buttons;
  };

  return (
    <>
      <Header
        screenTitle={renderScreenTitle()}
        hasBack
        isLoading={isFetching || isUpdating || isMerging || isCreating}
        buttons={renderActionButtons()}
      />
      <div style={{ width: '95%', margin: '10px auto 0 auto', paddingBottom: FAB_BOTTOM_SPACING }}>
        {hasPermissions([PERMISSION.CREATE_DEFAULT_ROUTES]) && (
          <AddFAB onClick={handleAddRouteClick} />
        )}

        <RoutesList
          routes={data?.defaultRoutes || []}
          onRouteClick={(route) => {
            navigate(getDefaultRouteRoute(route.id));
          }}
          onToggleRouteSelect={toggleRouteSelect}
          selectedRoutes={selectedRoutes}
          onEditRouteClick={handleEditRouteClick}
        />
      </div>

      {isCreateRoutesModalVisible && (
        <DefaultRouteEditorModal
          isEdit={false}
          onSaveClick={handleSaveAddRouteClick}
          onCancel={handleCancelAddRouteClick}
        />
      )}

      {routeToEdit && (
        <DefaultRouteEditorModal
          isEdit
          route={routeToEdit}
          onSaveClick={handleSaveEditRouteClick}
          onCancel={() => {
            setRouteToEdit(null);
          }}
        />
      )}

      {isPromptMergeRoutesModalVisible && (
        <PromptModal
          title="איחוד מסלולים"
          inputLabel="שם המסלול החדש"
          onSaveClick={handleSaveMergeClick}
          onCancel={handleCancelMergeClick}
        />
      )}

      <AlertModal
        isVisible={isAlertMergeRoutesModalVisible}
        onConfirm={handleConfirmMergeAlertClick}
      >
        יש לבחור לפחות 2 מסלולים לאיחוד
      </AlertModal>
    </>
  );
};
