import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Header, HeaderButton } from '../../components/header';
import { EditIcon, UnpublishIcon } from '../../assets/svgs';
import { useAuthPermissions } from '../../hooks/auth';
import { PERMISSION } from '../../consts/permissions';
import { FAB_BOTTOM_SPACING, ShareFAB } from '../../components/share-fab';
import { useResetAllAddressesStatuses } from '../../queries/address';
import { ConfirmationModal } from '../../components/confirmation-modal';
import { useDistribution } from '../../queries/distributions';
import { DistributionNotActiveWarning } from '../../components/not-active-warning';
import { RoutesList } from './routes-list';

export const RoutesScreen = () => {
  const navigate = useNavigate();
  const { distributionId = '' } = useParams();

  const [isConfirmResetModalVisible, setConfirmResetModalVisibility] = useState(false);

  const {
    isFetching,
    data: distribution,
    refetch: reloadRoutes,
    isLoading,
  } = useDistribution(distributionId);
  const { mutate: mutateResetAddressesStatuses, isLoading: isResetAddressesStatuses } =
    useResetAllAddressesStatuses();
  const { hasPermissions } = useAuthPermissions();

  const handleEditRoutesClick = () => {
    navigate(`/distribution/${distributionId}/routes/edit`);
  };

  const handleResetAddressesStatuses = () => {
    setConfirmResetModalVisibility(true);
  };

  const handleConfirmResetClick = () => {
    setConfirmResetModalVisibility(false);

    mutateResetAddressesStatuses(
      {
        distributionId,
      },
      {
        onSuccess: () => {
          reloadRoutes();
        },
      },
    );
  };

  const handleCancelResetClick = () => {
    setConfirmResetModalVisibility(false);
  };

  const defineHeaderButtons = () => {
    const buttons: HeaderButton[] = [];

    if (hasPermissions([PERMISSION.EDIT_ROUTES])) {
      buttons.push({
        title: 'איפוס חבילות שנמסרו',
        icon: <UnpublishIcon />,
        onclick: handleResetAddressesStatuses,
      });
    }

    if (hasPermissions([PERMISSION.EDIT_ROUTES])) {
      buttons.push({ title: 'עריכה', icon: <EditIcon />, onclick: handleEditRoutesClick });
    }

    return buttons;
  };

  let totalPackages = 0;
  let totalAddresses = 0;
  if (distribution?.routes) {
    for (const route of distribution.routes) {
      if (route.isActive) {
        totalPackages += route.totalPackages ?? 0;
        totalAddresses += route.totalAddresses ?? 0;
      }
    }
  }

  return (
    <>
      <Header
        screenTitle="מסלולים"
        screenSubTitle={!isLoading ? `${totalPackages} אריזות / ${totalAddresses} כתובות` : ''}
        hasBack
        isLoading={isFetching || isResetAddressesStatuses}
        buttons={defineHeaderButtons()}
      />
      <div style={{ width: '95%', margin: '10px auto 0 auto', paddingBottom: FAB_BOTTOM_SPACING }}>
        {!isLoading && !distribution?.isActive && <DistributionNotActiveWarning />}

        <ShareFAB message="היי, אלה כל המסלולים שקיימים:" />

        <RoutesList
          routes={distribution?.routes.filter((route) => route.isActive) || []}
          onRouteClick={(route) => {
            navigate(`/distribution/${distributionId}/route/${route.id}`);
          }}
        />
      </div>
      <ConfirmationModal
        title="איפוס חבילות שנמסרו"
        isVisible={isConfirmResetModalVisible}
        onConfirm={handleConfirmResetClick}
        onCancel={handleCancelResetClick}
      >
        בטוח שברצונך לאפס את כל הסימונים על חבילות שנמסרו?
      </ConfirmationModal>
    </>
  );
};
