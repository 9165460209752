import { User } from '../../../@types/users';
import { UserListItem } from './user-list-item';

type UsersListProps = {
  users: User[];
  onUserClick: (user: User) => void;
};

export const UsersList = ({ users, onUserClick }: UsersListProps) => {
  if (users.length <= 0) {
    return <div>אין משתמשים להצגה</div>;
  }

  return (
    <>
      {users.map((user) => {
        return (
          <UserListItem
            onClick={() => {
              onUserClick(user);
            }}
            key={user.id}
            user={user}
          />
        );
      })}
    </>
  );
};
