import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { Header, HeaderButton } from '../../components/header';
import { useRoute } from '../../queries/route';
import { getAddressLocatorRoute, getRoutePeople } from '../../utils/routes';
import { AddressesList } from '../../components/addresses-list';
import { EditIcon } from '../../assets/svgs';
import { useAuthPermissions } from '../../hooks/auth';
import { PERMISSION } from '../../consts/permissions';
import { FAB_BOTTOM_SPACING, ShareFAB } from '../../components/share-fab';
import { RouteNotActiveWarning } from '../../components/not-active-warning';
import ScreenEnd from './screen-end';

export const RouteOverviewScreen = () => {
  const { routeId = '', distributionId = '' } = useParams();
  const navigate = useNavigate();

  const { isFetching, data: route, isLoading } = useRoute(routeId);
  const { hasPermissions } = useAuthPermissions();

  const isAllDelivered =
    (route &&
      route.addresses.length > 0 &&
      route.addresses.every((address) => {
        return address.status !== null;
      })) ||
    false;

  const [isEndedScreenVisible, setEndedScreenVisibility] = useState(false);

  useEffect(() => {
    if (isAllDelivered) {
      setEndedScreenVisibility(true);
    }
  }, [isAllDelivered]);

  const handleEditRouteClick = () => {
    navigate(`/distribution/${distributionId}/route/${routeId}/edit`);
  };

  const handleHideEndedScreenClick = useCallback(() => {
    setEndedScreenVisibility(false);
  }, []);

  const defineHeaderButtons = () => {
    const buttons: HeaderButton[] = [];

    if (hasPermissions([PERMISSION.EDIT_ROUTES])) {
      buttons.push({ title: 'עריכה', icon: <EditIcon />, onclick: handleEditRouteClick });
    }

    return buttons;
  };

  const { driver, passengers } = route || {};

  const renderContent = () => {
    if (isEndedScreenVisible) {
      return <ScreenEnd onHideEndedScreenClick={handleHideEndedScreenClick} />;
    }

    return (
      <AddressesList
        addresses={route?.addresses ?? []}
        onAddressClick={(address) => {
          navigate(getAddressLocatorRoute(routeId, address.id));
        }}
      />
    );
  };

  return (
    <>
      <Header
        screenTitle={route?.name ?? 'רשימת כתובות במסלול'}
        screenSubTitle={getRoutePeople(driver, passengers)}
        hasBack
        isLoading={isFetching}
        buttons={defineHeaderButtons()}
      />
      <div style={{ width: '95%', margin: '10px auto 0 auto', paddingBottom: FAB_BOTTOM_SPACING }}>
        {!isLoading && !route?.isActive && <RouteNotActiveWarning />}

        {!isEndedScreenVisible && <ShareFAB message="היי, הנה קישור לצפייה במסלול:" />}

        {renderContent()}
      </div>
    </>
  );
};
