import styled from 'styled-components';
import { COLORS } from '../../consts/colors';

export const NotActiveBadge = styled.span`
  display: inline-block;
  border: 1px solid ${COLORS.LIGHT_RED};
  color: ${COLORS.LIGHT_RED};
  font-size: 16px;
  padding: 0px 4px;
  border-radius: 8px;
  position: relative;
  // top: -2px;
  margin-right: 5px;
`;
