import { DefaultRoute } from "../@types/default-routes";
import { formatDefaultRoute } from "./formatters/default-routes";
import { axiosInstance } from ".";

type GetAllDefaultRouteResponse = {
  defaultRoutes: DefaultRoute[];
}

export const getAllDefaultRoutes = async () => {
  const result = await axiosInstance.get(`/get-default-routes.php`);

  return result.data as GetAllDefaultRouteResponse;
};

export const getDefaultRoute = async (routeId: string) => {
  const result = await axiosInstance.get(`/get-default-route.php?route=${routeId}`);

  return formatDefaultRoute(result.data);
};

export const createDefaultRoute = async (
  newRouteName: string,
) => {
  await axiosInstance.post('/create-default-route.php', {
    routeName: newRouteName,
  });
};

export const setDefaultRouteInfo = async (
  routeId: string,
  name: string,
) => {
  await axiosInstance.post('/set-default-route-info.php', {
    routeId,
    name,
  });
};

export const mergeDefaultRoutes = async (
  newRouteName: string,
  routes: string[],
) => {
  await axiosInstance.post('/merge-default-routes.php', {
    routeName: newRouteName,
    routesList: routes,
  });
};

export const moveAddressesToNewDefaultRoute = async (
  oldRouteId: string,
  newRouteName: string,
  addresses: string[],
) => {
  await axiosInstance.post('/move-to-new-default-route.php', {
    oldRouteId,
    routeName: newRouteName,
    addressesList: addresses,
  });
};

export const setDefaultRouteOrder = async (
  routeId: string,
  addresses: string[],
) => {
  await axiosInstance.post('/set-default-route-order.php', {
    routeId,
    addressesList: addresses,
  });
};

export const deleteAddressesFromDefaultRoute = async (
  routeId: string,
  addresses: string[],
) => {
  await axiosInstance.post('/delete-addresses-from-default-route.php', {
    routeId,
    addressesList: addresses,
  });
};

export const linkAddressesToDefaultRoute = async (
  routeId: string,
  addresses: string[],
) => {
  await axiosInstance.post('/link-addresses-to-default-route.php', {
    routeId,
    addressesList: addresses,
  });
};
