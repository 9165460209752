type Location = {
  latitude: number,
  longitude: number,
}

type Media = {
  url: string,
  type: 'image' | 'video' | 'audio'
}

export enum APARTMENT_STATUS {
  OPENED = 'OPENED',
  NOT_OPENED = 'NOT_OPENED',
}

export type Address = {
  id: string,
  createdAt: Date,
  active: boolean,
  city: string,
  street: string,
  buildingNumber: number,
  entrance?: string | null,
  addressInstructions?: string | null,
  code?: string | null,
  floor?: number | null,
  apartmentNumber?: number | null,
  apartmentNotes?: string | null,
  familyName?: string | null,
  familyPhoneNumber?: string | null,
  generalNotes?: string | null,
  internalNotes?: string | null,
  contactName?: string | null,
  contactPhoneNumber?: string | null,
  contactTitle?: string | null,
  location?: Location | null,
  totalPackages: number,
  oneTime?: string | null,
  medias?: Media[] | null,
  status?: APARTMENT_STATUS | null,
  wasPackageLeft: boolean | null,
  inUse?: boolean,
  _isNewAddress: boolean,
};

export type ExtendedAddress = Address & {
  routes: string // todo: in the future, return ids / objects
};

export type AddressHistoryRecord = {
  distributionId: string,
  distributionName: string,
  date: Date,
  inDistribution: boolean,
  status: APARTMENT_STATUS,
  wasPackageLeft: boolean,
}

export type AddressHistory = {
  records: AddressHistoryRecord[]
}
