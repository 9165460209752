import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VerticalOverflowIcon } from '../../assets/svgs';
import {
  BackButton,
  Backdrop,
  ButtonWrapper,
  Content,
  Loader,
  OverflowMenuItem,
  OverflowMenuItemIcon,
  OverflowMenuItemTitle,
  OverflowMenuWrapper,
  ScreenSubTitle,
  /* MenuButton, */ ScreenTitle,
  Wrapper,
} from './style';

export type HeaderButton = {
  title: string;
  onclick: () => void;
  icon: React.ReactElement;
  disabled?: boolean;
};

type HeaderProps = {
  screenTitle?: string;
  screenSubTitle?: string;
  hasBack?: boolean;
  buttons?: HeaderButton[];
  isLoading?: boolean;
  maxVisibleButtons?: number;
};

export const Header = ({
  screenTitle,
  screenSubTitle,
  hasBack,
  buttons,
  isLoading,
  maxVisibleButtons = 3,
}: HeaderProps) => {
  const navigate = useNavigate();

  const [isOverflowMenuVisible, setOverflowMenuVisibility] = useState(false);

  // const handleMenuButtonClick = () => {
  //   alert('clicked');
  // }

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const renderNavigationButton = () => {
    if (hasBack) {
      return <BackButton onClick={handleBackButtonClick} />;
    }

    return null;
    // return <MenuButton onClick={handleMenuButtonClick}/>;
  };

  const renderMenuButtons = () => {
    if (!buttons) {
      return;
    }

    const totalVisibleButtons =
      buttons.length <= maxVisibleButtons ? buttons.length : maxVisibleButtons; // we keep 1 place for the overflow menu

    let buttonIdx = 0;

    const visibleButtons = [];

    while (buttonIdx < totalVisibleButtons) {
      const button = buttons[buttonIdx];

      visibleButtons.push(
        <ButtonWrapper
          title={button.title}
          onClick={() => {
            if (!button.disabled) {
              button.onclick();
            }
          }}
          disabled={button.disabled}
        >
          {button.icon}
        </ButtonWrapper>,
      );
      buttonIdx++;
    }

    if (buttonIdx < buttons.length) {
      const hiddenButtons = [];

      while (buttonIdx < buttons.length) {
        const button = buttons[buttonIdx];

        hiddenButtons.push(
          <OverflowMenuItem
            onClick={() => {
              if (!button.disabled) {
                button.onclick();
              }
            }}
            disabled={button.disabled}
          >
            <OverflowMenuItemIcon>{button.icon}</OverflowMenuItemIcon>
            <OverflowMenuItemTitle>{button.title}</OverflowMenuItemTitle>
          </OverflowMenuItem>,
        );
        buttonIdx++;
      }

      visibleButtons.push(
        <>
          <ButtonWrapper title="עוד אפשרויות" onClick={() => setOverflowMenuVisibility(true)}>
            <VerticalOverflowIcon />
          </ButtonWrapper>
          {isOverflowMenuVisible && (
            <Backdrop onClick={() => setOverflowMenuVisibility(false)}>
              <OverflowMenuWrapper>{hiddenButtons}</OverflowMenuWrapper>
            </Backdrop>
          )}
        </>,
      );
    }

    return visibleButtons;
  };

  return (
    <>
      <Wrapper>
        {renderNavigationButton()}

        <Content>
          <ScreenTitle>{screenTitle}</ScreenTitle>
          <ScreenSubTitle>{screenSubTitle}</ScreenSubTitle>
        </Content>

        {renderMenuButtons()}

        {isLoading && <Loader />}
      </Wrapper>
    </>
  );
};
