import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";
import { APARTMENT_STATUS } from "../@types/address";
import {
  AddressPayload,
  createAddress,
  getAllAddresses,
  moveAddressesToNewRoute,
  resetAllAddressesStatuses,
  setAddressActiveState,
  setAddressStatus,
  updateAddress,
} from "../api/address";
import { CONFIG } from "../config";

export const useAddresses = (showInactive?: boolean) => {
  const includeInactive = !!showInactive;

  return useQuery(['addresses', includeInactive], () => {
    return getAllAddresses(includeInactive);
  }, {
    retry: false,
    refetchInterval: CONFIG.AUTO_REFETCH_INTERVAL,
    keepPreviousData: true,
  });
};

export const useCreateAddress = () => {
  return useMutation<
    void,
    AxiosError,
    {
      payload: AddressPayload,
    }>(['create-address'], async ({ payload }) => {
      return createAddress(payload);
    });
};

export const useUpdateAddress = (addressId: string) => {
  return useMutation<
    void,
    AxiosError,
    {
      payload: AddressPayload,
    }>(['update-address', addressId], async ({ payload }) => {
      return updateAddress(addressId, payload);
    });
};

export const useSetAddressStatus = (
  routeId: string,
  addressId: string,
) => {
  return useMutation<
    void,
    AxiosError,
    {
      newStatus: APARTMENT_STATUS | null,
      wasPackageLeft: boolean,
    }>(['address-status', routeId, addressId], async ({ newStatus, wasPackageLeft }) => {
      return setAddressStatus(routeId, addressId, newStatus, wasPackageLeft);
    });
};

export const useSetAddressActiveState = (
  addressId: string,
) => {
  return useMutation<
    void,
    AxiosError,
    {
      newState: boolean,
    }>(['address-active-state', addressId], async ({ newState }) => {
      return setAddressActiveState(addressId, newState);
    });
};

export const useMoveAddressesToNewRoute = () => {
  return useMutation<
    void,
    AxiosError,
    {
      distributionId: string,
      oldRouteId: string,
      newRouteName: string,
      AddressesList: string[],
    }>(['addresses-new-route'], async ({
      distributionId, oldRouteId, newRouteName, AddressesList,
    }) => {
      return moveAddressesToNewRoute(distributionId, oldRouteId, newRouteName, AddressesList);
    });
};

export const useResetAllAddressesStatuses = () => {
  return useMutation<
    void,
    AxiosError,
    {
      distributionId: string,
    }>(['reset-addresses-statuses'], async ({ distributionId }) => {
      return resetAllAddressesStatuses(distributionId);
    });
};
