import styled from 'styled-components';
import { COLORS } from '../../consts/colors';
import { CloseIcon } from '../../assets/svgs';

export const Overlay = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-height: 90%;
  overflow: hidden;
  background-color: ${COLORS.WHITE};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px 20px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.04), 0 1px 6px -1px rgba(0, 0, 0, 0.03),
    0 2px 4px 0 rgba(0, 0, 0, 0.03);
  align-items: center;
  background-color: ${COLORS.DARK_BLUE};
`;

export const CloseButton = styled(CloseIcon)`
  order: 2;
  cursor: pointer;
  width: 40px;
  margin-right: 10px;
  margin-left: -5px;
  fill: ${COLORS.WHITE};
`;

export const Title = styled.div`
  flex: 1;
  font-size: 32px;
  color: ${COLORS.WHITE};
`;

export const Content = styled.div`
  display: block;
  width: 100%;
  flex: 1;
  background-color: 1;
  overflow: auto;
  padding: 20px 20px;
  position: relative;
`;
