import { Distribution } from '../../../../@types/distributions';
import { LeftArrowIcon } from '../../../../assets/svgs';
import { COLORS } from '../../../../consts/colors';
import { Title, Wrapper } from './style';

type DistributionListItemProps = {
  distribution: Distribution;
  onClick: () => void;
};

export const DistributionListItem = ({ distribution, onClick }: DistributionListItemProps) => {
  return (
    <Wrapper onClick={onClick}>
      <Title>{distribution.name}</Title>
      <LeftArrowIcon style={{ fill: COLORS.DARKER_GRAY }} width={20} height={20} />
    </Wrapper>
  );
};
