import { Checkbox } from '../checkbox';
import { ErrorMessage, LabelText, LabelWrapper } from './style';

type CheckboxInputProps = {
  isChecked: boolean;
  onToggleCheck: () => void;
  label: string;
  hasError?: boolean;
  errorMessage?: string;
};

export const CheckboxInput = ({
  isChecked,
  onToggleCheck,
  label,
  hasError = false,
  errorMessage,
}: CheckboxInputProps) => {
  return (
    <LabelWrapper onClick={onToggleCheck}>
      <Checkbox isChecked={isChecked} />
      <LabelText>{label}</LabelText>
      {hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </LabelWrapper>
  );
};
