import { DefaultRoute } from '../../../../@types/default-routes';
import { useDefaultRoutes } from '../../../../queries/default-routes';
import { DefaultRouteListItem } from './default-route-list-item';
import { ListContainer } from './style';

type DefaultRoutesSelectorProps = {
  selectedIds: string[];
  onDefaultRouteSelect: (route: DefaultRoute) => void;
};

export const DefaultRoutesSelector = ({
  onDefaultRouteSelect,
  selectedIds,
}: DefaultRoutesSelectorProps) => {
  const { isFetching, data } = useDefaultRoutes();

  if (isFetching) {
    return <div>טוען...</div>;
  }

  if (!data || data.defaultRoutes.length <= 0) {
    return <div>לא קיימים מסלולים קבועים</div>;
  }

  return (
    <ListContainer>
      {data.defaultRoutes.map((defaultRoute) => {
        return (
          <DefaultRouteListItem
            key={defaultRoute.id}
            route={defaultRoute}
            isSelected={selectedIds.includes(defaultRoute.id)}
            onClick={() => {
              onDefaultRouteSelect(defaultRoute);
            }}
          />
        );
      })}
    </ListContainer>
  );
};
