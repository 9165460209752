import styled from 'styled-components';
import { COLORS } from '../../consts/colors';

export const LabelWrapper = styled.label`
  display: block;
  width: 100%;
`;

export const LabelText = styled.span`
  font-weight: 400;
`;

export const CustomTextarea = styled.textarea<{
  hasError: boolean;
}>`
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 15px;
  border: 1px solid ${({ hasError }) => (hasError ? COLORS.LIGHT_RED : COLORS.DARKER_GRAY)};
  resize: vertical;
  min-height: 50px;

  &:disabled {
    border-color: ${COLORS.LIGHT_GRAY};
    background-color: ${COLORS.EXTRA_LIGHT_GRAY};
  }
`;

export const ErrorMessage = styled.div`
  display: block;
  width: 100%;
  color: ${COLORS.LIGHT_RED};
`;
