import { Route } from '../../../../@types/routes';
import { LeftArrowIcon } from '../../../../assets/svgs';
import { PackagesBadge } from '../../../../components/badges';
import { COLORS } from '../../../../consts/colors';
import { getRoutePeople } from '../../../../utils/routes';
import { People, Content, TotalAddressesVisitedBadge, Wrapper, TitleRow } from './style';

type RouteListItemProps = {
  route: Route;
  onClick: () => void;
};

export const RouteListItem = ({ route, onClick }: RouteListItemProps) => {
  const { driver, passengers } = route;

  const startedDelivery = route.totalAddressesVisited && route.totalAddressesVisited > 0;

  const renderTotalVisited = () => {
    if (startedDelivery) {
      return (
        <TotalAddressesVisitedBadge>
          {`נמסרו ${route.totalAddressesVisited} מתוך ${route.totalAddresses}`}
        </TotalAddressesVisitedBadge>
      );
    }
  };

  return (
    <Wrapper onClick={onClick}>
      <Content>
        <TitleRow>
          {route.name}
          {!startedDelivery && <PackagesBadge>{route.totalPackages} אריזות</PackagesBadge>}
        </TitleRow>
        <People>{getRoutePeople(driver, passengers)}</People>
      </Content>
      {renderTotalVisited()}
      <LeftArrowIcon style={{ fill: COLORS.DARKER_GRAY }} width={20} height={20} />
    </Wrapper>
  );
};
