import styled from 'styled-components';
import { COLORS } from '../../consts/colors';

export const Text = styled.div`
  display: block;
  width: 100%;
  background-color: ${COLORS.LIGHT_RED};
  padding: 5px;
  text-align: center;
  color: ${COLORS.WHITE};
  border-radius: 20px;
  margin-bottom: 20px;
`;
