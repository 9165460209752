import styled from 'styled-components';
import { COLORS } from '../../../../consts/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  padding: 15px 0;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid ${COLORS.EXTRA_LIGHT_GRAY};
  gap: 10px;

  &:last-of-type {
    border-bottom: 0;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 24px;
`;

export const TitleRow = styled.div``;

export const StatusIcon = styled.div<{ isOnline: boolean }>`
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: ${({ isOnline }) => (isOnline ? '#00CC00' : '#CC0000')};
  transition: all 0.5s;
`;

export const RoleBadge = styled.span`
  display: inline-block;
  border: 1px solid ${COLORS.DARK_GRAY};
  color: ${COLORS.DARK_GRAY};
  padding: 0px 6px;
  border-radius: 12px;
  margin-right: 10px;
  font-size: 16px;
`;

export const LastActivity = styled.div`
  color: ${COLORS.DARK_GRAY};
  font-size: 16px;
`;

export const DateTimeValue = styled.span`
  display: inline-block;
  direction: ltr;
  color: inherit;
  font-size: inherit;
  margin-right: 3px;
`;
