import { useMutation } from "react-query";
import { AxiosError } from "axios";
import {
  createRoute,
  linkAddressesToRoute,
  mergeRoutes,
  setRoutesActiveState,
} from "../api/routes";

export const useCreateRoute = () => {
  return useMutation<
    void,
    AxiosError,
    {
      distributionId: string,
      routeName: string,
    }>(['create-route'], async ({ distributionId, routeName }) => {
      return createRoute(distributionId, routeName);
    });
};

export const useMergeRoutes = () => {
  return useMutation<
    void,
    AxiosError,
    {
      distributionId: string,
      newRouteName: string,
      routesIds: string[],
    }>(['merge-routes'], async ({ distributionId, newRouteName, routesIds }) => {
      return mergeRoutes(distributionId, newRouteName, routesIds);
    });
};

export const useSetRoutesActiveState = () => {
  return useMutation<
    void,
    AxiosError,
    {
      routesIds: string[],
      newState: boolean,
    }>(['set-active-routes'], async ({ routesIds, newState }) => {
      return setRoutesActiveState(routesIds, newState);
    });
};

export const useLinkAddressesToRoute = (
  routeId: string,
) => {
  return useMutation<
    void,
    AxiosError,
    {
      addresses: string[]
    }>(['link-addresses-to-route', routeId], async ({ addresses }) => {
      return linkAddressesToRoute(routeId, addresses);
    });
};
