import { DefaultRoute } from '../../../../../@types/default-routes';
import { Checkbox } from '../../../../../components/checkbox';
import { Title, Wrapper } from './style';

type DefaultRouteListItemProps = {
  route: DefaultRoute;
  isSelected: boolean;
  onClick: () => void;
};

export const DefaultRouteListItem = ({ route, isSelected, onClick }: DefaultRouteListItemProps) => {
  return (
    <Wrapper onClick={onClick}>
      <Checkbox isChecked={isSelected} />
      <Title>{route.name}</Title>
    </Wrapper>
  );
};
