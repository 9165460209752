import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { Header, HeaderButton } from '../../components/header';
import { EditIcon, MapsIcon, TimelineIcon, WazeIcon } from '../../assets/svgs';
import { useRoute } from '../../queries/route';
import { useSetAddressStatus } from '../../queries/address';
import { useAuthPermissions } from '../../hooks/auth';
import { PERMISSION } from '../../consts/permissions';
import { AddressHistoryModal } from '../../components/address-history-modal';
import { AddressView } from './address';

export const AddressLocatorScreen = () => {
  const { routeId = '', addressId = '' } = useParams();
  const [searchParams, setQuery] = useSearchParams();
  const navigate = useNavigate();

  const isHistoryModalVisible = searchParams.get('showHistory') === 'true';

  const [isMapVisible, setMapVisible] = useState(false);

  const { isLoading, data: route, refetch } = useRoute(routeId);
  const { hasPermissions } = useAuthPermissions();

  const { mutate: mutateAddressStatus, isLoading: isUpdatingAddressStatus } = useSetAddressStatus(
    routeId,
    addressId,
  );

  const address = route?.addresses?.find((addressData) => {
    return addressData.id.toString() === addressId;
  });

  const handleWazeClick = () => {
    if (!address) {
      return;
    }

    const addressLocation = `${address.location?.latitude},${address.location?.longitude}`;
    window.open(`waze://?ll=${addressLocation}&navigate=yes`);
  };

  const handleMapsClick = () => {
    setMapVisible(!isMapVisible);
    // window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${addressLocation}`)
  };

  const handleEditAddressClick = () => {
    navigate(`/address/${addressId}/edit`);
  };

  const handleViewAddressHistoryClick = () => {
    setQuery(
      {
        showHistory: 'true',
      },
      { replace: true },
    );
  };

  const handleHideAddressHistoryClick = () => {
    setQuery(
      {
        showHistory: 'false',
      },
      { replace: true },
    );
  };

  const renderButtons = () => {
    const buttons: HeaderButton[] = [];

    if (address?.location?.latitude && address?.location?.longitude) {
      buttons.push({
        title: 'ניווט ב-Waze',
        icon: <WazeIcon />,
        onclick: handleWazeClick,
        disabled: !address,
      });
    }

    if (hasPermissions([PERMISSION.EDIT_ADDRESSES])) {
      buttons.push({
        title: 'עריכה',
        icon: <EditIcon />,
        onclick: handleEditAddressClick,
        disabled: !address,
      });
    }

    buttons.push({
      title: 'מפה',
      icon: <MapsIcon />,
      onclick: handleMapsClick,
      disabled: !address,
    });

    if (hasPermissions([PERMISSION.VIEW_ADDRESSES_HISTORY])) {
      buttons.push({
        title: 'היסטוריית חלוקה',
        icon: <TimelineIcon />,
        onclick: handleViewAddressHistoryClick,
      });
    }

    return buttons;
  };

  const renderAddress = () => {
    if (!address) {
      return <div>אין מידע</div>;
    }

    return (
      <AddressView
        data={address}
        showMap={isMapVisible}
        reloadData={refetch}
        isUpdatingAddressStatus={isUpdatingAddressStatus}
        isRouteActive={route?.isActive}
        onStatusChange={(payload, onSuccess) => {
          mutateAddressStatus(payload, {
            onSuccess: () => {
              if (typeof onSuccess === 'function') {
                onSuccess();
              }
            },
          });
        }}
      />
    );
  };

  return (
    <>
      <Header
        screenTitle="איתור כתובת"
        hasBack
        isLoading={isLoading || isUpdatingAddressStatus}
        buttons={renderButtons()}
        maxVisibleButtons={2}
      />

      {renderAddress()}

      {isHistoryModalVisible && (
        <AddressHistoryModal addressId={addressId} onClose={handleHideAddressHistoryClick} />
      )}
    </>
  );
};
