import { Address, APARTMENT_STATUS } from '../@types/address';
import {
  formatAddress,
  formatAddressHistory,
  formatAllAddresses,
  formatAllExtendedAddresses,
} from './formatters/addresses';
import { axiosInstance } from '.';

export type AddressPayload = Omit<Address, 'id'>;

export const getAllAddresses = async (includeInactive: boolean) => {
  const result = await axiosInstance.get(
    `/get-all-addresses.php?includeInactive=${includeInactive}`,
  );

  return formatAllExtendedAddresses(result.data);
};

export const getAddress = async (addressId: string) => {
  const result = await axiosInstance.get(`/get-address.php?address=${addressId}`);

  return formatAddress(result.data);
};

export const getAddressHistory = async (addressId: string) => {
  const result = await axiosInstance.get(`/get-address-history.php?addressId=${addressId}`);

  return formatAddressHistory(result.data);
};

export const updateAddress = async (addressId: string, data: AddressPayload) => {
  await axiosInstance.post(`/update-address.php?addressId=${addressId}`, data);
};

export const createAddress = async (data: AddressPayload) => {
  await axiosInstance.post(`/create-address.php`, data);
};

export const setAddressStatus = async (
  routeId: string,
  addressId: string,
  newStatus: APARTMENT_STATUS | null,
  wasPackageLeft: boolean,
) => {
  const status = newStatus ?? 'NONE';

  await axiosInstance.post('/set-address-status.php', {
    routeId,
    addressId,
    newStatus: status,
    wasPackageLeft,
  });
};

export const setAddressActiveState = async (
  addressId: string,
  newState: boolean,
) => {
  await axiosInstance.post('/set-address-active.php', {
    addressId,
    newState,
  });
};

export const moveAddressesToNewRoute = async (
  distributionId: string,
  oldRouteId: string,
  newRouteName: string,
  addresses: string[],
) => {
  await axiosInstance.post('/move-to-new-route.php', {
    distributionId,
    oldRouteId,
    routeName: newRouteName,
    addressesList: addresses,
  });
};

export const resetAllAddressesStatuses = async (
  distributionId: string,
) => {
  await axiosInstance.post('/reset-addresses-statuses.php', {
    distributionId,
  });
};

export const getNotOpenedAddresses = async (totalDistributions: number) => {
  const result = await axiosInstance.get(
    `/get-addresses-not-opened.php?totalDistributions=${totalDistributions}`,
  );

  return formatAllAddresses(result.data);
};
