import { PERMISSION } from "../../consts/permissions";

export enum AUTH_ACTION_TYPES {
  SUCCESS_LOGIN = 'SUCCESS_LOGIN',
  LOADED_USER_INFO = 'LOADED_USER_INFO',
  LOGOUT = 'LOGOUT',
};

type LoginAction = {
  type: AUTH_ACTION_TYPES.SUCCESS_LOGIN;
};

type MeAction = {
  type: AUTH_ACTION_TYPES.LOADED_USER_INFO;
  payload: {
    permissions: PERMISSION[]
  };
}

type LogoutAction = {
  type: AUTH_ACTION_TYPES.LOGOUT;
};

export type AuthAction = LoginAction | LogoutAction | MeAction;
