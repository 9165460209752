import { useState } from 'react';
import { ActionButton } from '../../../components/action-button';
import { Modal } from '../../../components/modal';
import { CheckboxInput } from '../../../components/checkbox-input';
import { ButtonsRow, CheckboxWrapper } from './style';

export type FilterOptions = { noRoutes: boolean; noWaze: boolean; multipleRoutes: boolean };

type FilterAddressesModalProps = {
  activeOptions: FilterOptions;
  onSaveClick: (options: FilterOptions) => void;
  onCancel: () => void;
};

export const FilterAddressesModal = ({
  activeOptions,
  onSaveClick,
  onCancel,
}: FilterAddressesModalProps) => {
  const [isNoRoutesChecked, setNoRoutesChecked] = useState(activeOptions.noRoutes);
  const [hasMultipleRoutesChecked, setHasMultipleRoutesChecked] = useState(
    activeOptions.multipleRoutes,
  );
  const [isNoWazeChecked, setNoWazeChecked] = useState(activeOptions.noWaze);

  const handleNoRoutesCheck = () => {
    setNoRoutesChecked(!isNoRoutesChecked);
    setHasMultipleRoutesChecked(false);
  };

  const handleMultipleRoutesCheck = () => {
    setHasMultipleRoutesChecked(!hasMultipleRoutesChecked);
    setNoRoutesChecked(false);
  };

  const handleNoWazeCheck = () => {
    setNoWazeChecked(!isNoWazeChecked);
  };

  const handleSaveClick = () => {
    onSaveClick({
      noRoutes: isNoRoutesChecked,
      noWaze: isNoWazeChecked,
      multipleRoutes: hasMultipleRoutesChecked,
    });
  };

  return (
    <Modal title="סינון כתובות" isVisible onClose={onCancel}>
      <form
        onSubmit={(event) => {
          event.stopPropagation();
          event.preventDefault();
          handleSaveClick();
        }}
      >
        <CheckboxWrapper>
          <CheckboxInput
            isChecked={isNoRoutesChecked}
            label="ללא שיוך למסלול"
            onToggleCheck={handleNoRoutesCheck}
          />
        </CheckboxWrapper>

        <CheckboxWrapper>
          <CheckboxInput
            isChecked={hasMultipleRoutesChecked}
            label="משוייך ליותר ממסלול אחד"
            onToggleCheck={handleMultipleRoutesCheck}
          />
        </CheckboxWrapper>

        <CheckboxWrapper>
          <CheckboxInput
            isChecked={isNoWazeChecked}
            label="ללא נ.צ. ל-Waze"
            onToggleCheck={handleNoWazeCheck}
          />
        </CheckboxWrapper>

        <ButtonsRow>
          <ActionButton title="שמירה" type="submit" />
          <ActionButton title="ביטול" onClick={onCancel} kind="secondary" />
        </ButtonsRow>
      </form>
    </Modal>
  );
};
