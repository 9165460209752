import styled from 'styled-components';
import { COLORS } from '../../consts/colors';

export const NewBadge = styled.span`
  display: inline-block;
  background-color: ${COLORS.LIGHT_BLUE};
  color: ${COLORS.WHITE};
  padding: 0px 6px;
  border-radius: 10px;
  font-size: 16px;
  margin-right: 10px;
`;

export const PackagesBadge = styled.span`
  display: inline-block;
  background-color: #ffd17d;
  padding: 0px 6px;
  border-radius: 8px;
  margin-right: 10px;
`;
