import { formatRoute } from "./formatters/routes";
import { axiosInstance } from ".";

export const getRoute = async (routeId: string) => {
  const result = await axiosInstance.get(`/get-route.php?route=${routeId}`);

  return formatRoute(result.data);
};

export const createRoute = async (
  distributionId: string,
  newRouteName: string,
) => {
  await axiosInstance.post('/create-route.php', {
    distributionId,
    routeName: newRouteName,
  });
};

export const setRouteInfo = async (
  routeId: string,
  name: string,
  driver: string,
  passengers: string,
) => {
  await axiosInstance.post('/set-route-info.php', {
    routeId,
    name,
    driver,
    passengers,
  });
};

export const setRouteOrder = async (
  routeId: string,
  addresses: string[],
) => {
  await axiosInstance.post('/set-route-order.php', {
    routeId,
    addressesList: addresses,
  });
};

export const setRoutesActiveState = async (
  routes: string[],
  newState: boolean,
) => {
  await axiosInstance.post('/set-active-routes.php', {
    routesList: routes,
    newState,
  });
};

export const mergeRoutes = async (
  distributionId: string,
  newRouteName: string,
  routes: string[],
) => {
  await axiosInstance.post('/merge-routes.php', {
    distributionId,
    routeName: newRouteName,
    routesList: routes,
  });
};

export const resetAllRoutesTeams = async (
  distributionId: string,
) => {
  await axiosInstance.post('/reset-routes-team.php', {
    distributionId,
  });
};

export const linkAddressesToRoute = async (
  routeId: string,
  addresses: string[],
) => {
  await axiosInstance.post('/link-addresses-to-route.php', {
    routeId,
    addressesList: addresses,
  });
};
