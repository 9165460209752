import { useNavigate } from 'react-router-dom';
import { Header } from '../../components/header';
import { Address } from '../../@types/address';
import { AddressForm } from '../../components/address-form';
import { useCreateAddress } from '../../queries/address';

export const AddressCreateScreen = () => {
  const navigate = useNavigate();

  const { mutateAsync: mutateAddress, isLoading: isCreatingAddress } = useCreateAddress();

  const handleSaveClick = async (newAddressInfo: Address) => {
    try {
      await mutateAddress(
        {
          payload: newAddressInfo,
        },
        {
          onSuccess: () => {
            navigate(-1);
          },
        },
      );
    } catch (error) {}
  };

  return (
    <>
      <Header screenTitle="הוספת כתובת חדשה" isLoading={isCreatingAddress} hasBack />

      <div style={{ width: '95%', margin: '10px auto 0 auto', paddingBottom: 100 }}>
        <AddressForm
          address={{
            city: 'פתח תקווה',
            totalPackages: 1,
          }}
          onSaveClick={handleSaveClick}
        />
      </div>
    </>
  );
};
