import { ActionButton } from '../action-button';
import { Modal } from '../modal';
import { ButtonsRow, Content } from './style';

type ConfirmationModalProps = {
  title: string;
  isVisible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  onClose?: () => void;
  children: string | React.ReactNode;
};

export const ConfirmationModal = ({
  title,
  children,
  isVisible,
  onConfirm,
  onCancel,
  onClose,
}: ConfirmationModalProps) => {
  return (
    <Modal title={title} isVisible={isVisible} onClose={onClose}>
      <Content>{children}</Content>

      <ButtonsRow>
        <ActionButton title="אישור" onClick={onConfirm} type="submit" />
        <ActionButton title="ביטול" onClick={onCancel} kind="secondary" />
      </ButtonsRow>
    </Modal>
  );
};
