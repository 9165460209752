import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { getNotOpenedAddresses } from '../../../api/address';
import { CONFIG } from '../../../config';
import { Address } from '../../../@types/address';
import { getEditAddressRoute } from '../../../utils/routes';
import { COLORS } from '../../../consts/colors';
import { AddressListItem } from './address-list-item';

export const NotOpenedAddressesStatistics = () => {
  const [totalDistributions, setTotalDistributions] = useState(3);

  const { data, isLoading } = useQuery(
    ['not-opened-addresses', totalDistributions],
    () => {
      return getNotOpenedAddresses(totalDistributions);
    },
    {
      enabled: !Number.isNaN(totalDistributions),
      retry: false,
      refetchInterval: CONFIG.AUTO_REFETCH_INTERVAL,
    },
  );

  const navigate = useNavigate();

  const handleAddressClick = (address: Address) => {
    navigate(getEditAddressRoute(address.id));
  };

  const handleTotalDistributionsValueChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTotalDistributions(parseInt(event.currentTarget.value));
  };

  const renderList = () => {
    if (isLoading) {
      return <div style={{ margin: '10px 0' }}>טוען...</div>;
    }

    if (!data || data.addresses.length <= 0) {
      return <div style={{ margin: '10px 0' }}>אין מידע</div>;
    }

    return data.addresses.map((address) => {
      return <AddressListItem address={address} onClick={() => handleAddressClick(address)} />;
    });
  };

  return (
    <>
      <div style={{ fontWeight: 400, color: COLORS.DARK_BLUE, marginTop: 20 }}>
        כתובות שלא פתחו
        <select
          value={totalDistributions}
          onChange={handleTotalDistributionsValueChanged}
          style={{
            margin: '0 5px',
            backgroundColor: COLORS.WHITE,
            border: 0,
            color: COLORS.DARKER_GRAY,
            fontWeight: 400,
            fontSize: 22,
          }}
        >
          <option value={1}>פעם אחת</option>
          <option value={2}>2 פעמים</option>
          <option value={3}>3 פעמים</option>
          <option value={4}>4 פעמים</option>
          <option value={5}>5 פעמים</option>
          <option value={6}>6 פעמים</option>
          <option value={7}>7 פעמים</option>
          <option value={8}>8 פעמים</option>
        </select>
      </div>
      <div
        style={{
          border: `1px solid ${COLORS.EXTRA_LIGHT_GRAY}`,
          padding: '0px 20px',
          marginTop: 10,
        }}
      >
        {renderList()}
      </div>
    </>
  );
};
