import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header, HeaderButton } from '../../components/header';
import { PlayIcon, StopIcon } from '../../assets/svgs';
import { useDistributions, useSetDistributionsActiveState } from '../../queries/distributions';
import { Distribution } from '../../@types/distributions';
import { AddFAB, FAB_BOTTOM_SPACING } from '../../components/add-fab';
import { PERMISSION } from '../../consts/permissions';
import { useAuthPermissions } from '../../hooks/auth';
import { CreateDistributionModal } from './create-distribution-modal';
import { DistributionsList } from './distributions-list';

export const DistributionsEditorScreen = () => {
  const navigate = useNavigate();
  const [selectedDistributions, setSelectedDistributions] = useState<string[]>([]);
  const [isCreateDistributionModalVisible, setCreateDistributionModalVisibility] = useState(false);

  const { hasPermissions } = useAuthPermissions();

  const { isFetching, data: distributions, refetch: refetchDistributions } = useDistributions();
  const { mutate: mutateDistributionsActiveState, isLoading: isUpdatingActiveState } =
    useSetDistributionsActiveState();

  const toggleDistributionSelect = (distribution: Distribution) => {
    const newSelectedDistributions = [...selectedDistributions];

    const itemIndex = newSelectedDistributions.findIndex((selectedItem) => {
      return selectedItem === distribution.id;
    });

    if (itemIndex >= 0) {
      newSelectedDistributions.splice(itemIndex, 1);
    } else {
      newSelectedDistributions.push(distribution.id);
    }

    setSelectedDistributions(newSelectedDistributions);
  };

  const changeDistributionsActiveState = (newState: boolean) => {
    mutateDistributionsActiveState(
      {
        distributionsIds: selectedDistributions,
        newState: newState,
      },
      {
        onSuccess: () => {
          setSelectedDistributions([]);
          refetchDistributions();
        },
      },
    );
  };

  const handleSetDistributionsActiveClick = () => {
    changeDistributionsActiveState(true);
  };

  const handleSetDistributionsNotActiveClick = () => {
    changeDistributionsActiveState(false);
  };

  const handleCreateDistributionClick = () => {
    setCreateDistributionModalVisibility(true);
  };

  const handleCancelCreateDistributionClick = () => {
    setCreateDistributionModalVisibility(false);
  };

  const handleDistributionCreated = () => {
    setCreateDistributionModalVisibility(false);
    refetchDistributions();
  };

  const renderScreenTitle = () => {
    if (selectedDistributions.length > 0) {
      return `נבחרו: ${selectedDistributions.length}`;
    }

    return 'עריכת חלוקות';
  };

  const renderActionButtons = () => {
    const buttons: HeaderButton[] = [];

    if (selectedDistributions.length > 0) {
      const isAllActive = distributions
        ?.filter((distribution) => selectedDistributions.includes(distribution.id))
        .every((distribution) => distribution.isActive);

      if (isAllActive) {
        buttons.push({
          title: 'עצירת חלוקה',
          icon: <StopIcon />,
          onclick: handleSetDistributionsNotActiveClick,
        });
      } else {
        buttons.push({
          title: 'הפעלת חלוקה',
          icon: <PlayIcon />,
          onclick: handleSetDistributionsActiveClick,
        });
      }
    }

    return buttons;
  };

  return (
    <>
      <Header
        screenTitle={renderScreenTitle()}
        hasBack
        isLoading={isFetching || isUpdatingActiveState}
        buttons={renderActionButtons()}
      />
      <div style={{ width: '95%', margin: '10px auto 0 auto', paddingBottom: FAB_BOTTOM_SPACING }}>
        {hasPermissions([PERMISSION.CREATE_DISTRIBUTIONS]) && (
          <AddFAB onClick={handleCreateDistributionClick} />
        )}

        <DistributionsList
          distributions={distributions || []}
          onDistributionClick={(distribution) => {
            navigate(`/distribution/${distribution.id}/routes`);
          }}
          onToggleDistributionSelect={toggleDistributionSelect}
          selectedDistributions={selectedDistributions}
        />
      </div>

      {isCreateDistributionModalVisible && (
        <CreateDistributionModal
          onDistributionCreated={handleDistributionCreated}
          onCancel={handleCancelCreateDistributionClick}
        />
      )}
    </>
  );
};
