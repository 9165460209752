import styled from 'styled-components';
import { COLORS } from '../../../consts/colors';

export const Wrapper = styled.div`
  display: block;
  width: 100%;
  text-align: center;
`;

export const RouteEndedMessage = styled.div`
  font-size: 36px;
  color: ${COLORS.DARK_BLUE};
  line-height: 36px;
`;

export const BlessingMessage = styled.div`
  font-size: 56px;
  color: ${COLORS.DARK_BLUE};
  line-height: 56px;
`;

export const SahiSentence = styled.div`
  font-size: 36px;
  color: ${COLORS.DARK_BLUE};
  line-height: 40px;
`;

export const BackToAddresses = styled.div`
  margin-top: 20px;
  font-size: 24px;
  color: ${COLORS.DARK_BLUE};
  text-decoration: underline;
  cursor: pointer;
`;
