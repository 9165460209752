import { Navigate } from 'react-router-dom';
import { PERMISSION } from '../../consts/permissions';
import { useAuthPermissions } from '../../hooks/auth';
import { useAuth } from '../../providers/auth/contexts';
import { LoginScreen } from '../../screens/login';
import { SCREENS } from '../../consts/screens';
import { LOGIN_STATE } from '../../providers/auth/state';

type ProtectedRouteProps = {
  children: JSX.Element;
  requiredPermissions: PERMISSION[];
};

export const ProtectedRoute = ({ children, requiredPermissions }: ProtectedRouteProps) => {
  const auth = useAuth();
  const { hasPermissions } = useAuthPermissions();

  if (auth.loginState !== LOGIN_STATE.LOGGED_IN) {
    return <LoginScreen />;
  }

  if (!hasPermissions(requiredPermissions)) {
    return <Navigate to={SCREENS.HOME} />;
  }

  return children;
};
