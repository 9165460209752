import styled from 'styled-components';
import { COLORS } from '../../consts/colors';

export const NewBadge = styled.div`
  display: block;
  position: absolute;
  top: 20px;
  left: -40px;
  transform: rotate(-45deg);
  background-color: ${COLORS.LIGHT_BLUE};
  color: ${COLORS.WHITE};
  font-weight: 400;
  line-height: 20px;
  padding: 0 50px;
  z-index: 1;

  &:after {
    content: 'חדש';
  }
`;
