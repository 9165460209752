import styled from 'styled-components';
import { COLORS } from '../../../../consts/colors';

export const Wrapper = styled.div<{ done: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  padding: 15px 0;
  align-items: center;
  cursor: pointer;
  opacity: ${({ done }) => (done ? '0.6' : '1')};
  border-bottom: 1px solid ${COLORS.EXTRA_LIGHT_GRAY};

  &:last-of-type {
    border-bottom: 0;
  }
`;

export const Title = styled.span<{
  isActive: boolean;
}>`
  ${({ isActive }) => {
    if (!isActive) {
      return `
        text-decoration: line-through;
        color: ${COLORS.DARK_GRAY}
      `;
    }
  }}
`;

export const OneTimeBadge = styled.span`
  display: inline-block;
  border: 1px solid ${COLORS.LIGHT_BLUE};
  color: ${COLORS.LIGHT_BLUE};
  padding: 0px 6px;
  border-radius: 12px;
  margin-right: 10px;
  font-size: 20px;
`;

export const RoutesWrapper = styled.div`
  display: inline-block;
  margin-right: auto;
`;

export const RouteName = styled.span`
  border: 1px solid ${COLORS.LIGHT_GREEN};
  color: ${COLORS.LIGHT_GREEN};
  font-weight: 400;
  border-radius: 12px;
  padding: 2px 5px;
  font-size: 18px;
  line-height: 16px;
  margin-right: 5px;
`;
