import { useEffect } from 'react';
import { CloseButton, Container, Content, Header, Overlay, Title } from './style';

type ModalProps = {
  title?: string;
  children: React.ReactNode;
  isVisible: boolean;
  onClose?: () => void;
};

export const Modal = ({ title, children, isVisible, onClose }: ModalProps) => {
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  const showCloseButton = typeof onClose === 'function';

  return (
    <Overlay>
      <Container>
        {(showCloseButton || !!title) && (
          <Header>
            {showCloseButton && <CloseButton onClick={onClose} />}
            <Title>{title}</Title>
          </Header>
        )}
        <Content>{children}</Content>
      </Container>
    </Overlay>
  );
};
