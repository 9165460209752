import { PERMISSION } from "../../consts/permissions";

export enum LOGIN_STATE {
  INITIALIZE,
  POST_LOGIN,
  LOGGED_IN,
  LOGGED_OUT
}

export type AuthState = {
  loginState?: LOGIN_STATE;
  permissions: PERMISSION[]
};

export const defaultAuthState: AuthState = {
  loginState: LOGIN_STATE.INITIALIZE,
  permissions: [],
};
