import styled from 'styled-components';
import { COLORS } from '../../consts/colors';

export const LabelWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const LabelText = styled.span`
  font-size: 22px;
  margin-right: 10px;
`;

export const ErrorMessage = styled.div`
  display: block;
  width: 100%;
  color: ${COLORS.LIGHT_RED};
`;
