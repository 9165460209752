import styled from 'styled-components';
import { COLORS } from '../../../consts/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  padding: 15px 0;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid ${COLORS.EXTRA_LIGHT_GRAY};

  &:last-of-type {
    border-bottom: 0;
  }
`;

export const Checkbox = styled.div<{ isChecked: boolean }>`
  display: flex;
  width: 26px;
  height: 26px;
  border: 1px solid ${COLORS.DARK_BLUE};
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin-left: 20px;
  background-color: ${({ isChecked }) => (isChecked ? COLORS.DARK_BLUE : COLORS.WHITE)};
  transition: all 0.15s;
`;

export const Title = styled.div`
  flex: 1;
`;
