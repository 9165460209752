export const COLORS = {
  LIGHT_BLUE: '#037bea',
  DARK_BLUE: '#00519c',
  DARKER_GRAY: '#403d3e',
  DARK_GRAY: '#8f8f9d',
  LIGHT_GRAY: '#d8d8d8',
  EXTRA_LIGHT_GRAY: '#EFEFEF',
  WHITE: '#ffffff',
  LIGHT_YELLOW: '#ffd17d',
  LIGHT_GREEN: '#9cb734',
  LIGHT_RED: '#b73434',
};
